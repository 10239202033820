import React from "react"
import { Link, graphql } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} siteMetadata={data.site.siteMetadata}>
      <SEO title="All posts" />

      <div style={{ marginBottom: "4rem" }}>
        <span style={{ fontWeight: 700 }}>{siteDescription}</span>
      </div>
      {posts.map(({ node: post }) => {
        const title = post.frontmatter.title || post.fields.slug
        return (
          <article key={post.fields.slug}>
            <header>
              <h3 style={{ marginBottom: 0 }}>
                <Link to={post.fields.slug}>{title}</Link>
              </h3>
            </header>
            <section>
              <div
                dangerouslySetInnerHTML={{
                  __html: post.html,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
